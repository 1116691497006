import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
padding:4rem 2rem;
z-index: 100;
position: relative;

    @media only screen and (min-width: 450px) {
        padding:8rem 4rem;
    }

    .cta{
    width: 100%;
    display: flex;
    position: relative;
    align-items: stretch;    
    box-shadow: 2px 1px 20px 2px rgba(0,0,0,0.1);
    flex-wrap: wrap;

        @media only screen and (min-width: 1024px) {
        flex-wrap: nowrap;
        }

        .image-wrapper{
        width: 100%;
        position: relative;
        padding-bottom: 50%;

            @media only screen and (min-width: 760px) {
            width: 30%;
            padding-bottom: 0%;
            }
            @media only screen and (min-width: 1024px) {
                width: 50%;
            }
        
            .image{
            height:100%;
            width: 100%;
            position: absolute;
            }

        }

        .text{
        width: 100%;
        height: 100%;
        padding:4rem;
        background:var(--white);

            @media only screen and (min-width: 760px) {
                width: 70%;
            }
            @media only screen and (min-width: 1024px) {
                width: 50%;
            }

            .title{
            margin-bottom: 2rem;
            }

            .text-inner{
            ${({theme}) => theme.fontSize('15')}  
            ${({theme}) => theme.fontSizeLineHeight('15', '1.75')}  
            max-width: 500px;
            margin-bottom: 4rem;
            position: relative;

                a{
                color:var(--blue_dark);
                text-decoration:none;
                border-bottom:1px solid var(--blue_dark);
                }

            }



        }

    }

`

export { Styling }