import React from 'react'
import styled from 'styled-components'
import dd from '../../images/dd.svg'

const Styling = styled.div`
width:100%;
position: relative;
    .filters-outer-wrap{
        width: 100%;
        position: relative;
        padding:0 2rem;
        margin-top:2rem;
        margin-bottom:2rem;
        display: inline-flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        @media only screen and (min-width: 450px) {
        padding:0 4rem;
        }

        @media only screen and (min-width: 1024px) {
        padding:0 10rem;
        margin-bottom:4rem;
        }

    }
    .filters-wrap{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-left:2rem;

        @media only screen and (min-width: 500px) {
        max-width:183px;
        }
        @media only screen and (max-width: 500px) {
        margin-left:0;
        &:first-child{margin-bottom: 2rem;}
        }
      

        label{
        width:100%;
        margin-bottom: 1rem;
        font-size:1.3rem;
        text-align:right;
        }

        .selectWrapper{
        position: relative;
        width: 100%;

            svg{
            position: absolute;
            right:1rem;
            top:50%;
            transform:translateY(-50%);
            pointer-events: none;
            }

        }

        select{
        padding:1rem;
        border:1px solid var(--blue_dark);
        -webkit-appearance:none;
        padding-right:2rem;
        min-width: 150px;
        width: 100%;
        -webkit-appearance:none;
        border-radius:0;
        background-color:var(--white);
        background-image:url(${dd});
        background-repeat:no-repeat;
        background-size:auto 30%;
        background-position:calc(100% - 1rem) 50%;

            &:focus{
            outline:none
            }

        }
    }


    .exdisplay-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    padding:0 2rem 2rem 2rem;
    
        @media only screen and (min-width: 450px) {
            padding:0 4rem 4rem 4rem;
        }
        @media only screen and (min-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (min-width: 850px) {
            padding:0 4rem 4rem 4rem;
            grid-template-columns: repeat(3, 1fr);
        }
    
        @media only screen and (min-width: 1024px) {
        padding:0 10rem 8rem 10rem;
        }
        @media only screen and (min-width: 1400px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media only screen and (min-width: 2200px) {
            grid-template-columns: repeat(6, 1fr);
        }
    /* background:var(--blue_light); */

        &.addPaddingTop{
        padding: 2rem;
    
            @media only screen and (min-width: 450px) {
                padding:4rem;
            }
        
            @media only screen and (min-width: 1024px) {            
            padding:8rem 10rem;
            }
         
        }

        .exdisplay-product{
        background:var(--white);
        display: flex;
        flex-wrap: wrap;
        text-decoration:none;
        color:inherit;
        }

        .image-wrapper{
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        display: block;
        cursor: pointer;

            img{
            width: 100% !important;
            height:100% !important;
            position: absolute !important;
            top:0 !important;
            left:0 !important;
            }
        
        }

        .image{
        //border:1rem solid var(--white);
        background:var(--white);

            &.gatsby-image-wrapper{
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;

                img{
                object-fit:contain !important;
                object-position: top;
                }

            }
        }

        .more-info{
        display:flex;
        align-items:center;
        margin-bottom:2rem;
        cursor:pointer;

            svg{
            margin-right:0.5rem;
            }

        }

        .product-info{
        position: relative;
        padding:1rem 2rem 2rem 2rem ;
        
        background:var(--white);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

            .article-title{
                margin-bottom: 2rem;
                ${({theme}) => theme.fontSize('22')}  
            }
            
            .colours,
            .manufacturer,
            .dimensions{
            color:var(--grey);
            }
            .dimensions,
            .manufacturer{
                margin-bottom: 1rem;
            }
            
            .colours{
            margin-bottom: 2rem;
            }

            .priceWrapper{
            display:flex;
            align-items:center;
            margin-bottom: 2rem;

                .price{
                font-size: clamp(1.6rem, 1.75vw, 2rem);
                line-height:1.75em;
                }
    
                .originalPrice{
                color:red;
                text-decoration:line-through;
                font-size: clamp(1.6rem / 1.25, 1.75vw / 1.25, 2rem / 1.25);
                line-height:1.75em;
                margin-right: 1rem;
                }
            }

            .button{
            align-self:flex-end;
            width: 100%;
            text-align:center;
            ${({theme}) => theme.buttonSolid('--blue_dark', '--blue_light')}  
            
                &:hover{
                    ${({theme}) => theme.buttonHover('--white', '--blue_dark')}  
                }
            }

        }

    }

`

export { Styling }