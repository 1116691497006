import React, {useState, useEffect, useRef} from 'react'
import gsap from 'gsap'
import Hero from '../components/global/Hero'
import ExDisplayOffscreen from '../components/exDisplay/exDisplayOffscreen'
import toggleOffPageShow from '../utils/toggleOffPageShow'
import toggleOffPageHide from '../utils/toggleOffPageHide'
import handleShowForm from '../utils/handleShowForm';
import handleHideForm from '../utils/handleHideForm';
import CTASection from '../components/global/CTASection'
import { graphql } from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { Styling } from '../styles/exDisplayArchive/styled.js'
import {FiStar} from 'react-icons/fi'
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import {BsChevronDown} from 'react-icons/bs'
import {Link} from 'gatsby'

export default function ExDisplayArchive({data, pageContext}) {
    const heroImage = pageContext.page.customHeroImage.heroImage
    const title = pageContext.page.title
    const currentCat = pageContext.currentCat
    const exDisplayProducts = data.exdisplay.nodes
    const [wishlistDrawerActive, setWishlistDrawerActive] = useState(false);
    const allExDisplayProducts = data.allExdisplay.nodes
    const wishlistCount = useRef()
    const [wishlist, setWishlist] = useState('');

    const handleShowWishlistDrawer = () => {
      
      if(wishlistDrawerActive === false){
        toggleOffPageShow()
        setWishlistDrawerActive(true)
      }else{
        toggleOffPageHide('.exdisplay-product')
        handleHideForm()
        setWishlistDrawerActive(false)
      }
    } 

    const handleRemoveItemFromWishlist = (e) => {
      const target = e.target
      const targetTitle = target.closest('.remove').getAttribute('data-product-title')

      const updatedWishlist = wishlist.filter(product => product.name !== targetTitle);
    
      setWishlist(updatedWishlist)
    }

    const handleInactiveProducts = () => {
      exDisplayProducts.forEach(item => {    
        gsap.to(`.exdisplay-product .image-wrapper`, {pointerEvents:'all', opacity:1});      
        gsap.to(`.exdisplay-product .product-info`, {pointerEvents:'all', opacity:1});      
      })
      if(wishlist){
        wishlist.forEach(item => {    
          gsap.to(`.exdisplay-product[data-title="${item.name}"] .image-wrapper`, {pointerEvents:'none', opacity:0.3});      
          gsap.to(`.exdisplay-product[data-title="${item.name}"] .product-info`, {pointerEvents:'none', opacity:0.3});      
        })      
      }
    }

    const handleAddToWishlist = (e) => {
      gsap.to('.wishlist-notification', {y:0, opacity:1, duration:1, ease:"expo.out", onComplete: function(){
        gsap.to('.wishlist-notification', {y:30, opacity:0, duration:1, ease:"expo.out", delay:1})
      }})

      const target = e.target
      
      const product = target.getAttribute('data-title')
      const price = target.getAttribute('data-price')

      setWishlist(
        [
          {
          name: product, 
          price: price,
          },
          ...wishlist
        ]
      )
  
    }

    const updateRoute = (e) => {
      navigate(`/ex-display/${e.target.value}`)
    }

    useEffect(() => {
      if(localStorage.getItem('wishlist') !== null){
        setWishlist(JSON.parse(localStorage.getItem('wishlist')))
      }
    }, [])

    useEffect(() => {
      if(typeof window !== 'undefined'){
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
      }
      handleInactiveProducts()
      if(wishlist !== null && wishlist.length === 0){
        toggleOffPageHide('.exdisplay-product')
        handleHideForm()
        setWishlistDrawerActive(false)
      }else{
        wishlistCount.current.classList.add('active')
      }
      console.log('wishlist',wishlist)
    }, [wishlist])

  
  

    return (
        <Styling>
          <Helmet>
            <title>{data.page.seo.title}</title>
            <meta name="description" content={data.page.seo.metaDesc} />
            <meta name="og:description" content={data.page.seo.metaDesc} />
            <meta property="og:title" content={data.page.seo.title} />          
            <meta property="og:image" content={'https://morlandbathrooms.com'+data.page.customHeroImage.heroImage.localFile.publicURL} />
          </Helmet>
          <div className="offpage-translate offpage-translate-bg"></div>
          <div className="offpage-translate">
            <Hero image={heroImage} title={title}/>
            {/* {
              pageContext.current === 1 ?
                <IntroSectionReuseable defaultPageFields={pageContext.page.defaultPageFields} />
              : ''
            } */}
           <div className="filters-outer-wrap">
             <div className="filters-wrap">
                <label htmlFor="filters">Select an ex-display category</label>
                <div className="selectWrapper">
                  <select name="" id="filters" onChange={(e) => {updateRoute(e)}}>
                    <option value="">All categories</option>
                    {data.cats.nodes.map((cat, index) => {
                      return (
                        <option selected={cat.slug === currentCat ? 'selected' : ''} value={cat.slug}>{cat.title}</option>
                      )
                    })}
                  </select>
                  <BsChevronDown />
                </div>
              </div>
              <div className="filters-wrap">
                <label htmlFor="filters-condition">Filter by condition</label>
                <div className="selectWrapper">
                  <select name="" id="filters-condition" onChange={(e) => {updateRoute(e)}}>
                    <option value="">All conditions</option>
                    {data.conditions.nodes.map((cat, index) => {
                      return (
                        <option selected={cat.slug === currentCat ? 'selected' : ''} value={cat.slug}>{cat.name}</option>
                      )
                    })}
                  </select>
                  <BsChevronDown />
                </div>
              </div>
            </div>
            <div className={pageContext.current === 1 ? "exdisplay-wrapper" : "exdisplay-wrapper"}>
            {exDisplayProducts.length === 0 ? <h2>Sorry we cannot find what you're looking for.</h2> : ''}
            {
              
                exDisplayProducts.map((product, index) => {
                    
                    const theImage = getImage(product?.customHeroImage?.heroImage?.localFile)
                    const {manufacturer, price, originalPrice, width, height, length} = product.exDisplayProductFields
                    const colours = product.colours.nodes
                    
                    
                    return(
                      <Link to={`/ex-display/${product.slug}`} className="exdisplay-product" key={`exdisplay_${index}`} data-title={product.title}>
                        <div className="image-wrapper" >
                          <GatsbyImage image={theImage} className="image" alt={'test'} />                        
                        </div>
                        <div className="product-info">
                          <div className="product-info-inner">
                            <h3 className="article-title">{product.title}</h3>
                            <div className="priceWrapper">
                              <div className="originalPrice">Was: {originalPrice}</div>
                              <div className="price">Now: {price}</div>
                            </div>
                          </div>
                          <span className="button">View product info</span>
                        </div>
                      </Link>
                    )
                })
            }
            </div>
            {/* <Pagination slug={'/ex-display/'} pageContext={pageContext} /> */}
          </div>
          <div className="wishlist-notification">
            You have added a product to your wishlist
          </div>
          <button ref={wishlistCount} className={`wishlist-count ${wishlist !== null && wishlist.length > 0 ? 'active' : ''}`} onClick={handleShowWishlistDrawer}>
            <FiStar />
            <div className="count">{wishlist !== null ? wishlist.length : '0'}</div>
            <div className="text">view wishlist</div>
          </button>
          <ExDisplayOffscreen settings={data.global} wishlist={wishlist !== null && wishlist.length !== 0 ? wishlist : []} exDisplayProducts={allExDisplayProducts} handleRemoveItemFromWishlist={handleRemoveItemFromWishlist} handleShowForm={handleShowForm}/>
          <Texture 
        className='texture full transform-offscreen-child' 
        opacity={0.05}  
        position='absolute'
        bottom="0"
        height={''}
        top="0"
        watchScroll={false}
        />
         <CTASection ctaType={'chat'} />
        </Styling>
    )
}

export const query = graphql`
  query($currentCat: String! ) {
    allExdisplay: allWpExDisplay(sort: {fields: date, order: DESC}) {
      nodes {
        title
        customHeroImage {
          heroImage{
              localFile{
                  childImageSharp {
                      gatsbyImageData(
                      width: 1500
                      placeholder: NONE
                      formats: [AUTO, WEBP]
                      )
                  }
              }
          }
        }
        exDisplayProductFields {
          price
          originalPrice
        }
      }
  }
  page: wpPage(title:{eq:"Ex-display"}) {
    seo {
      title
      metaDesc
    }
    customHeroImage {
      heroImage{
          localFile{
            publicURL
          }
      }
    }
  }
  cats:  allWpProduct {
    nodes {
      slug
      title
    }
  }
  global: wp {
    siteSettings {
      acfOptions {
        enquireNowText
        productDisclaimerText
      }
    }
  }
   conditions:  allWpCondition {
    nodes {
      name
      slug
    }
  }
    exdisplay:  allWpExDisplay(sort: {fields: menuOrder}, filter: {exDisplayProductFields: {productCategorySetter: {eq: $currentCat}}}) {
        nodes {
          title
          slug
          customHeroImage {
            heroImage{
                localFile{
                    childImageSharp {
                        gatsbyImageData(
                        width: 1500
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                        )
                    }
                }
            }
          }
          colours {
            nodes {
              slug
              name
            }
          }
          exDisplayProductFields {
            manufacturer {
              ... on WpBrand {
                title
                slug
              }
            }
            price
            originalPrice
            width
            height
            depth
            productDescription
            productCategorySetter
          }
        }
    }
}
`