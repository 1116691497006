import React, {useRef, useState} from 'react'
import { Styling } from './styled.js'
import { useStaticQuery, graphql } from "gatsby"
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import parse from 'html-react-parser'
import Form from '../Form'

export default function CTASection({ctaType}) {
    const data = useStaticQuery(graphql`
    {
      wp {
        siteSettings {
          acfOptions {
            ctaText
            ctaImage {
              localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 2000
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                    )
                }
              }
            }
            ctaTitle
          }
        }
      }
    }
  `)
  
  const cta = data.wp.siteSettings.acfOptions
  const theImage = getImage(cta.ctaImage.localFile)


    return (
        <Styling>
              <div className="cta">
                  <div className="image-wrapper">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                  </div>
                  <div className="text">
                      <div className="title libre large">{cta.ctaTitle}</div>
                      <div className="text-inner">{parse(cta.ctaText)}</div>
                      <div className="form">
                      <Form />
                      </div>
                  </div>
              </div>
        </Styling>
    )
}
